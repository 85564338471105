<template>
  <v-layout v-if="getPermission('part:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Part Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pr-2 font-weight-700 required font-size-16 width-100"
                  >Name</label
                >
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Part ID (Stock Accountable)</label
                >
              </div>
              <div class="d-flex">
                <TextField
                  dense
                  filled
                  label="Name"
                  solo
                  flat
                  counter="100"
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.name"
                  :rules="[
                    validateRules.required(partCreate.name, 'Name'),
                    validateRules.minLength(partCreate.name, 'Name', 1),
                    validateRules.maxLength(partCreate.name, 'Name', 100),
                  ]"
                ></TextField>
                <TextField
                  dense
                  filled
                  label="Part ID (Stock Accountable)"
                  solo
                  flat
                  counter="100"
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.part_id_stock"
                ></TextField>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Application</label
                >
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Inclusion</label
                >
              </div>
              <div class="d-flex">
                <TextField
                  dense
                  filled
                  label="Application"
                  solo
                  flat
                  counter="100"
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.application"
                ></TextField>
                <TextField
                  dense
                  filled
                  label="Inclusion"
                  solo
                  flat
                  counter="100"
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.inclusion"
                ></TextField>
              </div>
              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >GDS Code</label
                >
              </div>
              <div class="d-flex w-50">
                <v-text-field
                  dense
                  filled
                  label="GDS Code"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 w-100"
                  :rules="[
                    validateRules.minLength(partCreate.gds_code, 'GDS Code', 1),
                    validateRules.maxLength(
                      partCreate.gds_code,
                      'GDS Code',
                      100
                    ),
                  ]"
                  v-model.trim="partCreate.gds_code"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="font-weight-700 font-size-16">Description</label>
              <v-textarea
                dense
                filled
                label="Description"
                solo
                flat
                row-height="20"
                auto-grow
                color="cyan"
                v-on:paste="(e) => onPaste(e)"
                v-on:keypress="(e) => manageLimits(e)"
                v-model.trim="partCreate.description"
                :rules="[
                  validateRules.minLength(
                    partCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    partCreate.description,
                    'Description',
                    500
                  ),
                ]"
              ></v-textarea>
              <div style="text-align: end">
                {{
                  partCreate.description ? partCreate.description.length : 0
                }}/500
              </div>
            </v-card-text>
          </v-card>

          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Unit Cost</label
                >
                <label class="pr-2 font-weight-700 font-size-16 width-100">
                  Unit Cost Currency</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Unit Cost"
                  solo
                  flat
                  class="width-50"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="(e) => manageLimit(e, partCreate.selling_cost)"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="partCreate.selling_cost"
                ></v-text-field>
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="currencyList"
                  label="Unit Cost Currency"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="currencyControll"
                  item-text="text"
                  item-value="text"
                  class="width-50"
                  v-model.trim="partCreate.unit_cost_currency"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Unit Cost Currency Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
              <div class="d-flex">
                <label
                  class="font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Cost FC
                </label>
                <label class="pr-2 font-weight-700 font-size-16 width-100">
                  Cost FC Currency</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Cost FC"
                  solo
                  flat
                  class="width-50"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    (e) => manageLimit(e, partCreate.purchase_cost)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="partCreate.purchase_cost"
                ></v-text-field>
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="currencyList"
                  label="Cost FC Currency "
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="currencyControll"
                  item-text="text"
                  item-value="text"
                  class="width-50"
                  v-model.trim="partCreate.purchase_cost_currency"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Cost FC Currency Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
            </v-card-text>
          </v-card>

          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Stock Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100 required"
                  >Unit
                </label>
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >PA Size/ Cut Quantity
                </label>
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Ivy Rate
                </label>
              </div>
              <div class="d-flex">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="unitList"
                  label="Unit "
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="UomData"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-100"
                  v-model.trim="partCreate.unit"
                  :rules="[validateRules.required(partCreate.unit, 'UOM')]"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Unit  Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="PA Size/ Cut Quantity"
                  solo
                  flat
                  v-mask="'######'"
                  class="pl-2 width-100"
                  v-model.trim="partCreate.initial_stock"
                ></v-text-field>
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="ivgList"
                  label="Ivy Rate "
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="Ivyrate"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-100"
                  v-model.trim="partCreate.lvy_rate"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Ivy Rate Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <!--    <TextField
                  dense
                  filled
                  label="Ivy Rate"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.lvy_rate"
                ></TextField> -->
              </div>
              <div class="d-flex">
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Remark
                </label>
              </div>
              <div class="d-flex">
                <TextField
                  dense
                  filled
                  label="Remark"
                  solo
                  flat
                  counter="250"
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="partCreate.remark"
                ></TextField>
              </div>
            </v-card-text>
          </v-card>

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Part Dimensions
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-40">
                  Length</label
                >
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-10">
                  Unit</label
                >
              </div>
              <div class="d-flex">
                <div class="w-40">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Length"
                    solo
                    flat
                    class="pr-2"
                    type="number"
                    min="0"
                    max="999"
                    v-model.trim="partCreate.length"
                    v-on:keypress="(e) => manageLimit(e, partCreate.length)"
                  ></v-text-field>
                </div>
                <div class="w-20">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Unit"
                    solo
                    flat
                    class="pl-2"
                    v-model.trim="partCreate.length_unit"
                    readonly
                  ></v-text-field>
                  <!--   <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2"
                    v-model.trim="partCreate.length_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete> -->
                </div>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-40">
                  Height</label
                >
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-10">
                  Unit</label
                >
              </div>
              <div class="d-flex">
                <div class="w-40">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Height"
                    solo
                    flat
                    class="pr-2"
                    type="number"
                    min="0"
                    max="999"
                    v-model.trim="partCreate.height"
                    v-on:keypress="(e) => manageLimit(e, partCreate.height)"
                  ></v-text-field>
                </div>
                <div class="w-20">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Unit"
                    solo
                    flat
                    class="pl-2"
                    v-model.trim="partCreate.height_unit"
                    readonly
                  ></v-text-field>

                  <!--  <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2"
                    v-model.trim="partCreate.height_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete> -->
                </div>
              </div>
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-40">
                  Weight</label
                >
                <label class="pr-2 pl-2 font-weight-700 font-size-16 w-10">
                  Unit</label
                >
              </div>
              <div class="d-flex">
                <div class="w-40">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Weight"
                    solo
                    flat
                    class="pr-2"
                    type="number"
                    min="0"
                    max="999"
                    v-model.trim="partCreate.weight"
                    v-on:keypress="(e) => manageLimit(e, partCreate.weight)"
                  ></v-text-field>
                </div>
                <div class="w-20">
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Unit"
                    solo
                    flat
                    class="pl-2"
                    v-model.trim="partCreate.weight_unit"
                    readonly
                  ></v-text-field>
                  <!--   <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2"
                    v-model.trim="partCreate.weight_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete> -->
                </div>
              </div>
            </v-card-text>
          </v-card>

          <template>
            <br />
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Part Images
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100">Images </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :file-type="true"
                      :attachments="partCreate.part_images"
                      v-on:file:updated="updateImages"
                      acceptFile="image/png, image/jpeg, image/jpg"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="p-6 font-size-16" v-if="false">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="partCreate.part_attachments"
                      v-on:file:updated="updateAttachments"
                      :file-type="true"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>

    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
    <ManageIvg
      :u-dialog="manageIvgDialog"
      :ivg="ivgList"
      v-on:close-dialog="manageIvgDialog = false"
      v-on:get-product-ivg="getOptions"
    ></ManageIvg>
    <ManageCurrency
      :u-dialog="manageCurrencyDialog"
      :currency="currencyList"
      v-on:close-dialog="manageCurrencyDialog = false"
      v-on:get-product-currency="getOptions"
    ></ManageCurrency>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import ManageIvg from "@/view/pages/partials/Manage-Part-Ivg.vue";
import ManageCurrency from "@/view/pages/partials/Manage-Currency.vue";
import {
  QUERY,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
/* import { map, compact } from "lodash"; */

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    partCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    ManageIvg,
    ManageUnit,
    ManageCurrency,
  },
  data() {
    return {
      proDuplicateId: null,
      timeoutLimit: 500,
      timeout: null,
      unitList: new Array(),
      ivgList: new Array(),
      currencyList: new Array(),
      barcode: null,
      manageUnitDialog: false,
      manageIvgDialog: false,
      manageCurrencyDialog: false,
      partCreate: {
        barcode: null,
        name: null,
        gds_code: null,
        description: null,
        product_type: "parts",
        purchase_cost: null,
        selling_cost: null,
        unit: null,
        initial_stock: null,
        length: null,
        weight: null,
        height: null,
        height_unit: "mm",
        weight_unit: "(Kg)/m",
        length_unit: "mm",
        images: [],
        attachments: [],
        part_images: [],
        part_attachments: [],
        item_no: null,
        part_id_stock: null,
        application: null,
        inclusion: null,
        lvy_rate: null,
        remark: null,
        purchase_cost_currency: "SGD",
        unit_cost_currency: "SGD",
      },
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },
    partCreateData: {
      deep: true,
      handler(param) {
        this.partCreate = param;
        /*  if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        } */

        //this.partCreate.barcode = param.barcode ? param.barcode : this.barcode;

        /* this.$nextTick(() => {
          if (
            param.warranty &&
            param.warranty.length &&
            param.warranty[0] &&
            param.warranty[0].value
          ) {
            this.warrantyActive = true;
           
          }
        }); */
      },
    },
    partCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("savePart", _this.partCreate);
        }, _this.timeoutLimit);

        if (_this.proDuplicateId) {
          this.partCreate.barcode = this.barcode;
        }
      },
    },
    "partCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.partCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 499) {
        let trimValue = finalval.substring(0, 500);
        this.partCreate.description = trimValue;
        e.preventDefault();
      }
    },
    manageLimits(e) {
      if (
        this.partCreate.description &&
        this.partCreate.description.length > 499
      ) {
        e.preventDefault();
      }
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },
    Ivyrate() {
      this.manageIvgDialog = true;
      this.getOptions();
    },
    currencyControll() {
      this.manageCurrencyDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.partCreate.images = param;
    },
    updateAttachments(param) {
      this.partCreate.attachments = param;
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.partCreate.product_type },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.partCreate.barcode
          ) {
            _this.partCreate.barcode = response.data.barcode;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.currency
          ) {
            _this.currencyList = response.data.currency;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.ivg
          ) {
            _this.ivgList = response.data.ivg;
          }
          if (!_this.updateMode) {
            if (
              !_this.parttId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.partCreate.barcode = response.data.barcode;
            }
            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.partCreate.barcode = response.data.barcode;
            }
            if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              //_this.partCreate.description = _this.barcodeSetting.description;
            }
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
